import { useEffect, useRef } from 'react';
import useGsapLoaded from './useGsapLoaded';
export default function useEbookSlideAnimate() {
  const ebookSlideRef = useRef<HTMLDivElement>(null);
  const refElements = [ebookSlideRef];
  const isGsapLoaded = useGsapLoaded(); // Using the hook to get the loading status of GSAP

  useEffect(() => {
    if (!isGsapLoaded) return; // Do not proceed with animation setup if GSAP is not loaded

    /**
     * Apply animation to an element using GSAP.
     * @param {React.MutableRefObject} ref - Reference to the element.
     */
    const animateElement = (ref: any) => {
      const downAnimationTimeline = window?.gsap?.timeline({
        repeat: 0,
        onComplete: () => {
          const rotateAnimationTimeline = window?.gsap?.timeline({
            repeat: -1
          });
          if (ref.current) {
            const children = ref.current.children;
            const rotatedElements = Array.from(children[children.length - 1].children);
            if (rotatedElements.length > 1) {
              rotatedElements.forEach((element: any) => {
                rotateAnimationTimeline.fromTo(element, {
                  opacity: 0,
                  y: 20
                }, {
                  y: -12,
                  opacity: 1,
                  duration: 1.5,
                  ease: 'elastic.out(0.5,0.3)',
                  visibility: 'visible'
                }).to(element, {
                  opacity: 1,
                  duration: 2
                }).to(element, {
                  opacity: 0
                });
              });
            }
          }
        }
      });
      downAnimationTimeline.fromTo(ref.current, {
        opacity: 0,
        y: -31
      }, {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: 'power3.out',
        visibility: 'visible',
        position: 'fixed'
      });
    };

    // Loop through each element reference and apply the animation
    refElements.forEach(animateElement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGsapLoaded]);
  return ebookSlideRef;
}