export type SysTypes = {
  id: string;
};

export type ButtonTypes = {
  sys: SysTypes;
  url: string;
  text: string;
  type: string;
  target: string;
};

/* eslint-disable no-unused-vars */
export enum GQL_PAGE_ID_TYPES {
  HOME_LANDING_PAGE = 'YMuf78qyPP0qLAG93xz9X',
  SPORTS_LANDING_PAGE = '7l1T0bHMga3S8kO1StbR9w',
  ORGANIZATIONS_LANDING_PAGE = '6qmMLHb2cLPyo9aDb3Krmt',
  APPLICATIONS_LANDING_PAGE = '53jeNa8tYipLA8bXq7VNgl',
  PRODUCTS_LANDING_PAGE = '7HcsRnDnRGw0OMlyyScmGQ',
  NEWS_AND_RESEARCH_LANDING_PAGE = '1zFeiNBsATi9qnJQqgaz56',
  GET_IN_TOUCH_PAGE = 'fvyQZ5EsGWhjbu4foi0sS',
  INTEGRATIONS_PAGE = '68s1Z5lKuPBov6pu8cdGD3',
  PRIVATE_POLICY_LANDING_PAGE = '5Ty2hJjLhT38RWOyK5zaZY',

  // PRODUCT LANDING PAGES
  AIRBANDS_LANDING_PAGE = '1eoa4MOKV5bkBNWHqePQBT',
  FORCEFRAME_LANDING_PAGE = '7LDPXrrM3mMJI8CT9HIziL',
  DYNAMO_LANDING_PAGE = '76YlSDVsCEwdCm9eMaNBn',
  FORCEDECKS_LANDING_PAGE = '4w3b5J3Q40xxXfWZrYmSwH',
  HUMANTRAK_LANDING_PAGE = '74Cdyj3xGqFAkmVCJEjJYs',
  MSKED_LANDING_PAGE = '2nXw2M9IBdtrzy1RTnsf00',
  NORDBORD_LANDING_PAGE = '2TcKLeoo6pqZ0zFNx1jqXo',
  SCOREIT_LANDING_PAGE = '1LPe8k9QsVH3NgGclMhkeh',
  SMARTSPEED_LANDING_PAGE = '7v7Fnpai2Xv5zZpL3bDc9W',
  VALDHUB_LANDING_PAGE = '4vn01Wo6hv998rc5EwvDF6',
  TELEHAB_LANDING_PAGE = '2hSWsaMQxSARyQziUdJ67J',
  MOVEHEALTH_LANDING_PAGE = '2C2du1biuKBd4kYIlE2FWo',
}
/* eslint-enable no-unused-vars */

export type MediaDataTypes = {
  url: string;
  description: string;
  height: number;
  width: number;
  contentType: string;
};

export type BlogSummaryTypes = {
  translationsCollection: {
    items: {
      slug: string;
      languageCode: string;
    }[];
  };
  languageCode: string;
  sys: SysTypes;
  total: string;
  author: string | null;
  title: string | null;
  slug: string | null;
  datePublished: string;
  thumbnail: MediaDataTypes;
  excerpt: string | null;
  categoriesCollection: CategoriesCollectionTypes | null;
};

export type categoriesTypes = {
  title: string;
  slug: string;
  name: CategoryTypes;
  sys: SysTypes;
};

export type CategoryTypes =
  | 'All'
  | 'Case Studies'
  | 'News & Announcements'
  | 'Guest Spot'
  | 'Guides'
  | 'Whitepapers'
  | 'Clinical Studies';

export type CategoriesCollectionTypes = {
  items: categoriesTypes[];
};

export type ContentTypes = {
  data: object;
  marks: any[];
  value: string;
  nodeType: string;
};

export type BodyCopyJsonTypes = {
  data: object;
  content: {
    data: object;
    content: ContentTypes[];
    nodeType: string;
  }[];
  nodeType: string;
};

export type BodyCopyLinksTypes = any;

export type BodyCopyTypes = {
  link: string;
  json: BodyCopyJsonTypes;
  links: BodyCopyLinksTypes;
};

export interface ButtonTopicItemTypes {
  sys: SysTypes;
  url: string;
  text: string;
  type: string;
  target: string;
}

// =============================================
//          META DATA QUERY RESPONSE TYPES
// =============================================
export type LandingPageMetaDataQueryResponseTypes = {
  page: {
    seoMetadata: {
      canonical: any;
      title: string;
      description: string;
      previewImage: {
        url: string;
      };
      index: string;
      follow: string;
    };
  };
};

export type MetadataQueryResponseTypes = {
  page: {
    items: [
      {
        languageCode: string;
        seoMetadata: {
          canonical: any;
          title: string;
          description: string;
          previewImage: {
            url: string;
          };
          index: string;
          follow: string;
        };
      },
    ];
  };
};
